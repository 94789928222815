import React, { Component } from "react";
import {Button, Grid} from 'semantic-ui-react';
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import './B11U1L10.scss'

export default class B11U1L10 extends Component {

   
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1

      
    };
    return (
      
<Grid>
<Grid.Column widescreen={1} largeScreen={1} computer={1}  tablet={1} mobile={1} > 
  
  </Grid.Column>

  <Grid.Column widescreen={14} largeScreen={14} computer={14}  tablet={14} mobile={14} > 
     
        <h2 className="lesson2title"><br></br>M2U5L3</h2>

        <Slider {...settings}>


          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U5L3%2F87f407c731441eb421c83c66697c8661-0.jpg?alt=media&token=ea7f7ab8-9320-41f9-9ee6-7a6269a09ce7'} alt="boohoo" className="img-responsive"/>
          <br/>
          </div>

          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U5L3%2F87f407c731441eb421c83c66697c8661-1.jpg?alt=media&token=f125f663-69b0-4d18-a25b-db5892ff29e8'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U5L3%2F87f407c731441eb421c83c66697c8661-2.jpg?alt=media&token=f727f10d-7a2f-48fe-aaa0-7dc947898ccc'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U5L3%2F87f407c731441eb421c83c66697c8661-3.jpg?alt=media&token=75495b67-5430-4510-85e8-0b25fe72c59c'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U5L3%2F87f407c731441eb421c83c66697c8661-4.jpg?alt=media&token=d6a37cfd-5d47-4157-97b0-17dff96b8552'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U5L3%2F87f407c731441eb421c83c66697c8661-5.jpg?alt=media&token=b2345f82-0c21-4f00-aa65-5e6abb6ecf5f'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U5L3%2F87f407c731441eb421c83c66697c8661-6.jpg?alt=media&token=f18a695a-5975-49c1-ae69-ae29ba26e7a8'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U5L3%2F87f407c731441eb421c83c66697c8661-7.jpg?alt=media&token=f67103dc-0eb8-4ac5-a49d-271393e77554'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U5L3%2F87f407c731441eb421c83c66697c8661-8.jpg?alt=media&token=c8ab936c-7ee4-4e31-89fa-03b431757f76'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U5L3%2F87f407c731441eb421c83c66697c8661-9.jpg?alt=media&token=455bef64-a3fe-42bf-92b2-ce18595a6c26'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U5L3%2F87f407c731441eb421c83c66697c8661-10.jpg?alt=media&token=b7097e34-778b-4b34-af12-12f17d31137f'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U5L3%2F87f407c731441eb421c83c66697c8661-11.jpg?alt=media&token=f9b19078-8643-4aa1-a1fc-6ca2200defc2'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U5L3%2F87f407c731441eb421c83c66697c8661-12.jpg?alt=media&token=1b14a83b-9790-4d61-91e4-1797719c4a5a'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U5L3%2F87f407c731441eb421c83c66697c8661-13.jpg?alt=media&token=a2aba601-8278-4635-b7a1-0f54f7884ed7'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U5L3%2F87f407c731441eb421c83c66697c8661-14.jpg?alt=media&token=ede9de86-2727-4146-a59a-c00bd128989e'} alt="boohoo" className="img-responsive"/>
          </div>
          
          
        </Slider>
        <div class="center"> 
        <Button href='https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U5L3%2FPlan%20secuencia%20did%C3%A1ctica.pdf?alt=media&token=614f8610-3b98-482f-8379-4551d73ee72c' download className="btn-download-dos">Download resources</Button>
       </div>
             
</Grid.Column>
<Grid.Column widescreen={1} largeScreen={1} computer={1}  tablet={1} mobile={1} > 
  
  </Grid.Column>
    </Grid>

    );
  }
}
