import React from 'react';
import { Nav, Navbar } from "react-bootstrap";
import { Logo } from "../../logo.png";

import "bootstrap/dist/css/bootstrap.min.css";

import './Hamburgesa.scss'

export default function Hamburgesa() {

    return (
        <Navbar collapseOnSelect expand={false} bg="dark" variant="dark">
      <Navbar.Brand href="/">
        
        Teacher Training  
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="mr-auto">
          <Nav.Link href="/lesson1">M2U1L1</Nav.Link>
          <Nav.Link href="/lesson2">M2U1L2</Nav.Link>
          <Nav.Link href="/lesson3">M2U2L1</Nav.Link>
          <Nav.Link href="/lesson4">M2U2L2</Nav.Link>
          <Nav.Link href="/lesson5">M2U3L1</Nav.Link>
          <Nav.Link href="/lesson6">M2U3L2</Nav.Link>
          <Nav.Link href="/lesson7">M2U4</Nav.Link>
          <Nav.Link href="/lesson8">M2U5L1</Nav.Link>
          <Nav.Link href="/lesson9">M2U5L2</Nav.Link>
          <Nav.Link href="/lesson10">M2U5L3</Nav.Link>

        </Nav>
      </Navbar.Collapse>
    </Navbar>
    )
}
