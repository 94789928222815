import React, { Component } from "react";
import { Button, Grid } from 'semantic-ui-react';
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// import '../../style.scss';
import './B11U1L3.scss';

export default class B11U1L3 extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
    };
    return (

      <Grid>
        <Grid.Column widescreen={1} largeScreen={1} computer={1} tablet={1} mobile={1} >

        </Grid.Column>

        <Grid.Column widescreen={14} largeScreen={14} computer={14} tablet={14} mobile={14} >

          <h2><br></br>M2U2L1</h2>
          <Slider {...settings}>
            <div>
              <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U2L1%2F31d75bed29ba882458b984aeef806a62-0.jpg?alt=media&token=417bd97a-af91-45c6-ae7e-c304bb18c574'} alt="boohoo" className="img-responsive" />
              <br />
            </div>
            <div>
              <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U2L1%2F31d75bed29ba882458b984aeef806a62-1.jpg?alt=media&token=ef7bea93-c323-4cc0-ab22-462474ec81b6'} alt="boohoo" className="img-responsive" />
            </div>
            <div>
              <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U2L1%2F31d75bed29ba882458b984aeef806a62-2.jpg?alt=media&token=5aa3e2b0-3ac8-4b31-b045-0f65c8a0eed9'} alt="boohoo" className="img-responsive" />
            </div>
            <div>
              <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U2L1%2F31d75bed29ba882458b984aeef806a62-3.jpg?alt=media&token=b0eed8e8-2c30-4c84-b0ec-318a830c0f72'} alt="boohoo" className="img-responsive" />
            </div>
            <div>
              <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U2L1%2F31d75bed29ba882458b984aeef806a62-4.jpg?alt=media&token=61bad64b-755d-4d75-a755-54739be0d581'} alt="boohoo" className="img-responsive" />
            </div>
            <div>
              <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U2L1%2F31d75bed29ba882458b984aeef806a62-5.jpg?alt=media&token=c59cf92a-3fad-48b2-8862-21f00fdf7df4'} alt="boohoo" className="img-responsive" />
            </div>
            <div>
              <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U2L1%2F31d75bed29ba882458b984aeef806a62-6.jpg?alt=media&token=627b980d-8fe2-435a-82c0-0a44472409c0'} alt="boohoo" className="img-responsive" />
            </div>
            <div>
              <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U2L1%2F31d75bed29ba882458b984aeef806a62-7.jpg?alt=media&token=c6b81bfc-67b1-4dff-b948-47cc77a2b8fb'} alt="boohoo" className="img-responsive" />
            </div>
            <div>
              <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U2L1%2F31d75bed29ba882458b984aeef806a62-8.jpg?alt=media&token=fdf1680b-6757-4f44-a650-17d8735a4bd7'} alt="boohoo" className="img-responsive" />
            </div>
            <div>
              <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U2L1%2F31d75bed29ba882458b984aeef806a62-9.jpg?alt=media&token=17f3564c-9d4e-450b-93cc-257a35263ec5'} alt="boohoo" className="img-responsive" />
            </div>
            <div>
              <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U2L1%2F31d75bed29ba882458b984aeef806a62-10.jpg?alt=media&token=5122af5a-757d-4f16-848f-255e76333c20'} alt="boohoo" className="img-responsive" />
            </div>
            <div>
              <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U2L1%2F31d75bed29ba882458b984aeef806a62-11.jpg?alt=media&token=4b4b4790-99ce-4e5c-bc56-1157b7b2d20d'} alt="boohoo" className="img-responsive" />
            </div>
            <div>
              <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U2L1%2F31d75bed29ba882458b984aeef806a62-12.jpg?alt=media&token=42138519-b170-4bf8-957b-4c13fcf9ba24'} alt="boohoo" className="img-responsive" />
            </div>
            <div>
              <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U2L1%2F31d75bed29ba882458b984aeef806a62-13.jpg?alt=media&token=51ecf29b-d1c1-4654-92ec-b70f39f75d80'} alt="boohoo" className="img-responsive" />
            </div>
            <div>
              <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U2L1%2F31d75bed29ba882458b984aeef806a62-14.jpg?alt=media&token=547a20ce-8a02-44cd-9812-a792a5fcf353'} alt="boohoo" className="img-responsive" />
            </div>
            <div>
              <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U2L1%2F31d75bed29ba882458b984aeef806a62-15.jpg?alt=media&token=46dbdf12-ac61-4b19-a080-0b5ed3a576eb'} alt="boohoo" className="img-responsive" />
            </div>
            <div>
              <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U2L1%2F31d75bed29ba882458b984aeef806a62-16.jpg?alt=media&token=b649b4e2-b6c1-4a5e-897c-64e13f8efd07'} alt="boohoo" className="img-responsive" />
            </div>
            <div>
              <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U2L1%2F31d75bed29ba882458b984aeef806a62-17.jpg?alt=media&token=edd0b2bc-dbbd-476a-9ff0-af758991fc82'} alt="boohoo" className="img-responsive" />
            </div>
            <div>
              <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U2L1%2F31d75bed29ba882458b984aeef806a62-18.jpg?alt=media&token=4709a479-333a-4257-b849-30db85faa99e'} alt="boohoo" className="img-responsive" />
            </div>
            <div>
              <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U2L1%2F31d75bed29ba882458b984aeef806a62-19.jpg?alt=media&token=66d2f64b-f2d5-4c2b-98d9-d204d417563a'} alt="boohoo" className="img-responsive" />
            </div>
            <div>
              <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U2L1%2F31d75bed29ba882458b984aeef806a62-20.jpg?alt=media&token=1e888632-baee-43ef-9604-7964e5c933a0'} alt="boohoo" className="img-responsive" />
            </div>
            
          </Slider>


        </Grid.Column>
        <Grid.Column widescreen={1} largeScreen={1} computer={1} tablet={1} mobile={1} >

        </Grid.Column>
      </Grid>


    );
  }
}


