
import React, { Component } from "react";
import {Button, Grid} from 'semantic-ui-react';
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import './B11U1L1.scss';

export default class B11U1L1 extends Component {

  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
    };

    return (

      // <div class="contenedor"> 
      // <div className="App">
      // <header className="App-header">
<Grid>

  <Grid.Column widescreen={1} largeScreen={1} computer={1}  tablet={1} mobile={1} > 
  
  </Grid.Column>

  <Grid.Column widescreen={14} largeScreen={14} computer={14}  tablet={14} mobile={14} > 
    <>
    <h2><br></br>M2U1L1</h2>
    <Slider {...settings}>

 
      <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U1L1%2F0590d3900ddb483612f17dd7ddab4905-0.jpg?alt=media&token=998f8821-ba51-4619-b652-ae74bbcb2857'} alt="boohoo" className="img-responsive"/>
          <br/>
          </div>
  
      <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U1L1%2F0590d3900ddb483612f17dd7ddab4905-1.jpg?alt=media&token=08f0ca64-4485-4165-a134-d630b1c18859'} alt="boohoo" className="img-responsive"/>
          </div> 
      
        
      <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U1L1%2F0590d3900ddb483612f17dd7ddab4905-2.jpg?alt=media&token=fcdd96e2-29cb-4df9-9c4d-f0fc400ba9ac'} alt="boohoo" className="img-responsive"/>
          </div>
 
      <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U1L1%2F0590d3900ddb483612f17dd7ddab4905-3.jpg?alt=media&token=f7a7b23f-8566-47e6-b5c9-f48cefbf8d82'} alt="boohoo" className="img-responsive"/>
          </div>
     
        
      <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U1L1%2F0590d3900ddb483612f17dd7ddab4905-4.jpg?alt=media&token=241489cb-ecb1-4537-aa18-df1a35683f09'} alt="boohoo" className="img-responsive"/>
          </div>
    
      
      <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U1L1%2F0590d3900ddb483612f17dd7ddab4905-5.jpg?alt=media&token=10331ea4-6ddf-4f57-bc8e-b3908c4b75af'} alt="boohoo" className="img-responsive"/>
          </div>
      
 
      <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U1L1%2F0590d3900ddb483612f17dd7ddab4905-6.jpg?alt=media&token=6daf3c82-229e-4e90-a011-663955a8e78f'} alt="boohoo" className="img-responsive"/>
          </div>
        

          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U1L1%2F0590d3900ddb483612f17dd7ddab4905-7.jpg?alt=media&token=b9ea3975-f66a-4d96-8305-b13d418812ca'} alt="boohoo" className="img-responsive"/>
          </div>
         

          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U1L1%2F0590d3900ddb483612f17dd7ddab4905-8.jpg?alt=media&token=2a7385fe-a59c-4438-bb59-83c89f26505a'} alt="boohoo" className="img-responsive"/>
          </div>
        

          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U1L1%2F0590d3900ddb483612f17dd7ddab4905-9.jpg?alt=media&token=47c77279-27b3-4ae0-bbed-7f49b42a66a5'} alt="boohoo" className="img-responsive"/>
          </div>
    

          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U1L1%2F0590d3900ddb483612f17dd7ddab4905-10.jpg?alt=media&token=33701bd0-f666-4d43-91b6-c110ab45ab2e'} alt="boohoo" className="img-responsive"/>
          </div>
        

          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U1L1%2F0590d3900ddb483612f17dd7ddab4905-11.jpg?alt=media&token=ddbedec9-b4eb-4258-ae43-82124afac3cc'} alt="boohoo" className="img-responsive"/>
          </div>
      

          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U1L1%2F0590d3900ddb483612f17dd7ddab4905-12.jpg?alt=media&token=16d2ecef-aed2-42a4-b535-adc7d8636fc7'} alt="boohoo" className="img-responsive"/>
          </div>
          

          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U1L1%2F0590d3900ddb483612f17dd7ddab4905-13.jpg?alt=media&token=1354f1f5-8572-46fe-a8bf-844705956c23'} alt="boohoo" className="img-responsive"/>
          </div>
         

          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U1L1%2F0590d3900ddb483612f17dd7ddab4905-14.jpg?alt=media&token=34d4af02-8273-4bf4-ab70-4ff205bae194'} alt="boohoo" className="img-responsive"/>
          </div>
        

          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U1L1%2F0590d3900ddb483612f17dd7ddab4905-15.jpg?alt=media&token=870c7483-47d9-4337-866c-5d8db486b70d'} alt="boohoo" className="img-responsive"/>
          </div>
        

          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U1L1%2F0590d3900ddb483612f17dd7ddab4905-16.jpg?alt=media&token=dd785a2d-69f9-40fc-bf89-78a90b213205'} alt="boohoo" className="img-responsive"/>
          </div>
         
        </Slider>
      </>
  </Grid.Column>
  
  <Grid.Column widescreen={1} largeScreen={1} computer={1}  tablet={1} mobile={1} > 
  
  </Grid.Column>
</Grid>
      //   </header>
      //   </div>
      // </div>
    );
  }
}
