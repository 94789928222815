import React, { Component } from "react";
import {Button, Grid} from 'semantic-ui-react';
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import './B11U1L9.scss'

export default class B11U1L9 extends Component {

   
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1

      
    };
    return (
      
<Grid>
<Grid.Column widescreen={1} largeScreen={1} computer={1}  tablet={1} mobile={1} > 
  
  </Grid.Column>

  <Grid.Column widescreen={14} largeScreen={14} computer={14}  tablet={14} mobile={14} > 
     
        <h2 className="lesson4title"><br></br>M2U5L2</h2>

        <Slider {...settings}>


          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U5L2%2F3975c2456c518273189ab13a49e96097-0.jpg?alt=media&token=bba3ed81-bb88-4582-8d43-a3f68a221b12'} alt="boohoo" className="img-responsive"/>
          <br/>
          </div>

          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U5L2%2F3975c2456c518273189ab13a49e96097-1.jpg?alt=media&token=21d4db9a-6f16-4a59-a1af-50e4c26e9d9e'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U5L2%2F3975c2456c518273189ab13a49e96097-2.jpg?alt=media&token=e374335b-b330-4531-9d8a-3cc8ece3b8b8'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U5L2%2F3975c2456c518273189ab13a49e96097-3.jpg?alt=media&token=6b3ee81c-b998-4346-a2e9-0425460bf193'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U5L2%2F3975c2456c518273189ab13a49e96097-4.jpg?alt=media&token=411f9839-9c40-48ae-9f28-85aac1b90d3d'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U5L2%2F3975c2456c518273189ab13a49e96097-5.jpg?alt=media&token=ff6716a9-fbf4-4722-b475-3baa38344f91'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U5L2%2F3975c2456c518273189ab13a49e96097-6.jpg?alt=media&token=3a9cfde7-94fe-4e55-a5ef-abf0bf60ba2c'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U5L2%2F3975c2456c518273189ab13a49e96097-7.jpg?alt=media&token=6f4dcb81-34aa-4835-ac26-ba9e041cf703'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U5L2%2F3975c2456c518273189ab13a49e96097-8.jpg?alt=media&token=099293aa-d184-40e6-93cf-d5644424629d'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U5L2%2F3975c2456c518273189ab13a49e96097-9.jpg?alt=media&token=69044a88-2dfa-4f2b-bc0e-052baa9770bc'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U5L2%2F3975c2456c518273189ab13a49e96097-10.jpg?alt=media&token=3439727e-b10e-4b78-bbb2-5448e573f8fe'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U5L2%2F3975c2456c518273189ab13a49e96097-11.jpg?alt=media&token=299777fa-b7fe-42ac-9535-a9f95692606a'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U5L2%2F3975c2456c518273189ab13a49e96097-12.jpg?alt=media&token=0bac73e9-aa2b-4762-8e89-12cbfa0d5f9e'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U5L2%2F3975c2456c518273189ab13a49e96097-13.jpg?alt=media&token=403018e8-a63f-41f6-8cb1-2a1e81a6f23d'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U5L2%2F3975c2456c518273189ab13a49e96097-14.jpg?alt=media&token=b44c0b86-b756-4f8e-9143-746db9163251'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U5L2%2F3975c2456c518273189ab13a49e96097-15.jpg?alt=media&token=52d20790-4d14-464e-af67-70a9759be6dd'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U5L2%2F3975c2456c518273189ab13a49e96097-16.jpg?alt=media&token=fe945f7c-3fcb-48a5-bdc8-25c2792328ad'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U5L2%2F3975c2456c518273189ab13a49e96097-17.jpg?alt=media&token=79a11f68-0ebf-4943-8dcf-2e9f7421032d'} alt="boohoo" className="img-responsive"/>
          </div>
          
        </Slider>
        <div class="center"> 
        <Button href='https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U5L3%2FEVALUACI%C3%93N%20SECUENCIA%20DID%C3%81CTICA.pdf?alt=media&token=20d56e60-b949-460f-9c59-0c71a91e0a5f' download className="btn-download-cuatro">Download resources</Button>
       </div>
             
</Grid.Column>
<Grid.Column widescreen={1} largeScreen={1} computer={1}  tablet={1} mobile={1} > 
  
  </Grid.Column>
    </Grid>

    );
  }
}
