import React from 'react';
import { Switch, Route } from 'react-router-dom';
import lesson1 from '../pages/B11U1L1/B11U1L1';
import lesson2 from '../pages/B11U1L2/B11U1L2';
import lesson3 from '../pages/B11U1L3/B11U1L3';
import lesson4 from '../pages/B11U1L4/B11U1L4';
import lesson5 from '../pages/B11U1L5/B11U1L5';
import lesson6 from '../pages/B11U1L6/B11U1L6';
import lesson7 from '../pages/B11U1L7/B11U1L7';
import lesson8 from '../pages/B11U1L8/B11U1L8';
import lesson9 from '../pages/B11U1L9/B11U1L9';
import lesson10 from '../pages/B11U1L10/B11U1L10';







export default function Routes() {

 return(

    <Switch>
      {/* <Route path='/Repositories' component={Repositories} /> */}
      <Route path='/lesson1' component={lesson1} />
      <Route path='/lesson2' component={lesson2} />
      <Route path='/lesson3' component={lesson3} />
      <Route path='/lesson4' component={lesson4} />
      <Route path='/lesson5' component={lesson5} />
      <Route path='/lesson6' component={lesson6} />
      <Route path='/lesson7' component={lesson7} />
      <Route path='/lesson8' component={lesson8} />
      <Route path='/lesson9' component={lesson9} />
      <Route path='/lesson10' component={lesson10} />





    
    </Switch>
 );

}

