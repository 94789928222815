import React, { Component } from "react";
import {Button, Grid} from 'semantic-ui-react';
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import './B11U1L6.scss'

export default class B11U1L6 extends Component {

   
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1

      
    };
    return (
      
<Grid>
<Grid.Column widescreen={1} largeScreen={1} computer={1}  tablet={1} mobile={1} > 
  
  </Grid.Column>

  <Grid.Column widescreen={14} largeScreen={14} computer={14}  tablet={14} mobile={14} > 
     
        <h2><br></br>M2U3L2</h2>

        <Slider {...settings}>


          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U3L2%2Fc6f6ed83f3c908091239f470655300d8-0.jpg?alt=media&token=5d4543db-2541-4468-9a69-19c183860d78'} alt="boohoo" className="img-responsive"/>
          <br/>
          </div>

          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U3L2%2Fc6f6ed83f3c908091239f470655300d8-1.jpg?alt=media&token=b44f17c8-ec69-45c4-a58b-115b5a86d5bc'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U3L2%2Fc6f6ed83f3c908091239f470655300d8-2.jpg?alt=media&token=ac210555-f454-4282-aa4e-22659c7982d8'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U3L2%2Fc6f6ed83f3c908091239f470655300d8-3.jpg?alt=media&token=08dc8e30-e021-4c93-a614-90f51440fca0'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U3L2%2Fc6f6ed83f3c908091239f470655300d8-4.jpg?alt=media&token=1b8b1923-3cf6-4df4-a1ca-09794d202cfb'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U3L2%2Fc6f6ed83f3c908091239f470655300d8-5.jpg?alt=media&token=1fc8a439-009d-4b85-9a78-fdea66408a7e'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U3L2%2Fc6f6ed83f3c908091239f470655300d8-6.jpg?alt=media&token=0e5afb04-b887-4e8c-b3cb-f4bf5edfa41f'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U3L2%2Fc6f6ed83f3c908091239f470655300d8-7.jpg?alt=media&token=0776a171-a020-4072-9b32-2c4f193f317c'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U3L2%2Fc6f6ed83f3c908091239f470655300d8-8.jpg?alt=media&token=f906f117-a77c-49be-8eee-e7eec902522e'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U3L2%2Fc6f6ed83f3c908091239f470655300d8-9.jpg?alt=media&token=ded525f0-fe35-4205-8397-b7f133da694a'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U3L2%2Fc6f6ed83f3c908091239f470655300d8-10.jpg?alt=media&token=d039d844-f1b9-4ce8-810e-962deb16feb2'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U3L2%2Fc6f6ed83f3c908091239f470655300d8-11.jpg?alt=media&token=caba0507-fb68-4287-9cb2-17f8d6019246'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U3L2%2Fc6f6ed83f3c908091239f470655300d8-12.jpg?alt=media&token=f72ec004-ad42-4853-8bf8-4eb5d8957011'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U3L2%2Fc6f6ed83f3c908091239f470655300d8-13.jpg?alt=media&token=923de374-046f-4fa1-b75f-077da64707cc'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U3L2%2Fc6f6ed83f3c908091239f470655300d8-14.jpg?alt=media&token=11f78647-398e-4dd1-986a-69702af3ea36'} alt="boohoo" className="img-responsive"/>
          </div>
          
          
        </Slider>
        
             
</Grid.Column>
<Grid.Column widescreen={1} largeScreen={1} computer={1}  tablet={1} mobile={1} > 
  
  </Grid.Column>
    </Grid>

    );
  }
}
