import React, { Component } from "react";
import {Button, Grid} from 'semantic-ui-react';
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import './B11U1L7.scss'

export default class B11U1L7 extends Component {

   
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1

      
    };
    return (
      
<Grid>
<Grid.Column widescreen={1} largeScreen={1} computer={1}  tablet={1} mobile={1} > 
  
  </Grid.Column>

  <Grid.Column widescreen={14} largeScreen={14} computer={14}  tablet={14} mobile={14} > 
     
        <h2><br></br>M2U4</h2>

        <Slider {...settings}>


          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U4%2F357959c29496e8867e247a3b4260067c-0.jpg?alt=media&token=147f584f-d2a2-4570-ba59-2069542f73de'} alt="boohoo" className="img-responsive"/>
          <br/>
          </div>

          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U4%2F357959c29496e8867e247a3b4260067c-1.jpg?alt=media&token=5ea3a3fd-0876-4095-878c-742d67e06e0c'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U4%2F357959c29496e8867e247a3b4260067c-2.jpg?alt=media&token=706c0ebe-d2d5-45b4-bd29-33023542d88f'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U4%2F357959c29496e8867e247a3b4260067c-3.jpg?alt=media&token=3e015a50-db30-4f8d-94c2-ffb5636d4ab7'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U4%2F357959c29496e8867e247a3b4260067c-4.jpg?alt=media&token=aca49666-7423-4037-9a4c-b40be30bcd01'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U4%2F357959c29496e8867e247a3b4260067c-5.jpg?alt=media&token=53f470b8-7666-4c1d-8a83-e72ef2d7a7f4'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U4%2F357959c29496e8867e247a3b4260067c-6.jpg?alt=media&token=dacc06c2-6ad8-4f26-8c82-2c2114d2d0b2'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U4%2F357959c29496e8867e247a3b4260067c-7.jpg?alt=media&token=414776db-d4b0-4c8d-9657-76c2856279b6'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U4%2F357959c29496e8867e247a3b4260067c-8.jpg?alt=media&token=a31b71e1-0c47-486a-bbde-aa44c6e861c4'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U4%2F357959c29496e8867e247a3b4260067c-9.jpg?alt=media&token=ded5df8c-88b5-4848-9d50-1b33728f1556'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U4%2F357959c29496e8867e247a3b4260067c-10.jpg?alt=media&token=688cefe9-ccd2-4a30-864f-b7a6c1fd7322'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U4%2F357959c29496e8867e247a3b4260067c-11.jpg?alt=media&token=da446643-3130-44fb-b862-846b0aee79fc'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U4%2F357959c29496e8867e247a3b4260067c-12.jpg?alt=media&token=65343121-92a5-4465-a033-b9fc566a846e'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U4%2F357959c29496e8867e247a3b4260067c-13.jpg?alt=media&token=e07b9ec0-95d2-4d9d-947f-41d8ac14f7dd'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U4%2F357959c29496e8867e247a3b4260067c-14.jpg?alt=media&token=171effad-f614-47ff-9c5c-eb6abb2ef053'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U4%2F357959c29496e8867e247a3b4260067c-15.jpg?alt=media&token=2a2241d8-4ecb-4334-8e84-7e153b465c41'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U4%2F357959c29496e8867e247a3b4260067c-16.jpg?alt=media&token=e4eb3ea0-9a44-45c4-b5dd-e66c31334114'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U4%2F357959c29496e8867e247a3b4260067c-17.jpg?alt=media&token=88598265-8254-491b-a68b-9f56b35922c9'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U4%2F357959c29496e8867e247a3b4260067c-18.jpg?alt=media&token=366c0716-016e-4a25-b03d-57c482302082'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U4%2F357959c29496e8867e247a3b4260067c-19.jpg?alt=media&token=55802a92-0b2c-42db-b154-a521f3f3ac61'} alt="boohoo" className="img-responsive"/>
          </div>
         
          
        </Slider>
        
             
</Grid.Column>
<Grid.Column widescreen={1} largeScreen={1} computer={1}  tablet={1} mobile={1} > 
  
  </Grid.Column>
    </Grid>

    );
  }
}
