import React, { Component } from "react";
import {Button, Grid} from 'semantic-ui-react';
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// import '../../style.scss';
import './B11U1L2.scss';

export default class B11U1L2 extends Component {

  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
    };
    return (
      // <div class="contenedor"> 
      // <div className="App">
      // <header className="App-header">
      <Grid>
         <Grid.Column widescreen={1} largeScreen={1} computer={1}  tablet={1} mobile={1} > 
  
  </Grid.Column>

  <Grid.Column widescreen={14} largeScreen={14} computer={14}  tablet={14} mobile={14} > 
        <h2><br></br>M2U1L2</h2>
        <Slider {...settings}>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U1L2%2F168591145426d2d0ca8e6f51a75fa6a2-0.jpg?alt=media&token=c340b8aa-97b9-4c57-917e-33ef5a627faa'} alt="boohoo" className="img-responsive"/>
          <br/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U1L2%2F168591145426d2d0ca8e6f51a75fa6a2-1.jpg?alt=media&token=aabb894d-9ff1-4391-8c29-79553578b7f7'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U1L2%2F168591145426d2d0ca8e6f51a75fa6a2-2.jpg?alt=media&token=31d40d3b-f73b-4a83-94f3-b48fc5f3a39e'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U1L2%2F168591145426d2d0ca8e6f51a75fa6a2-3.jpg?alt=media&token=350faf88-ec7d-4bac-90c8-53d5c5636982'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U1L2%2F168591145426d2d0ca8e6f51a75fa6a2-4.jpg?alt=media&token=b78c653e-ad0b-45d8-b802-0e8474f26cd6'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U1L2%2F168591145426d2d0ca8e6f51a75fa6a2-5.jpg?alt=media&token=eec39cba-dca3-4b27-a9f6-d152ad3b4bac'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U1L2%2F168591145426d2d0ca8e6f51a75fa6a2-6.jpg?alt=media&token=966bb557-a3e1-4225-9397-9606c23183ba'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U1L2%2F168591145426d2d0ca8e6f51a75fa6a2-7.jpg?alt=media&token=3f9f8424-96fa-4fb4-a467-38f7d7f7960d'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U1L2%2F168591145426d2d0ca8e6f51a75fa6a2-8.jpg?alt=media&token=21047e39-d95c-47fc-a51c-0777d32e6cd0'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U1L2%2F168591145426d2d0ca8e6f51a75fa6a2-9.jpg?alt=media&token=1bf5f8a9-6c4b-4379-889b-9de0173300a6'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U1L2%2F168591145426d2d0ca8e6f51a75fa6a2-10.jpg?alt=media&token=852dbccb-36fa-4604-8025-5048c50ca043'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U1L2%2F168591145426d2d0ca8e6f51a75fa6a2-11.jpg?alt=media&token=9a9b5272-c7d2-4636-ad88-b0526b3c3e1b'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U1L2%2F168591145426d2d0ca8e6f51a75fa6a2-12.jpg?alt=media&token=bffc12bd-d88e-4cbe-88aa-103bc52366f2'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U1L2%2F168591145426d2d0ca8e6f51a75fa6a2-13.jpg?alt=media&token=72ca9630-9604-4bba-9b4d-fada307b41d7'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U1L2%2F168591145426d2d0ca8e6f51a75fa6a2-14.jpg?alt=media&token=1ae839e9-8169-4a4f-8c99-6c231569c3d0'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U1L2%2F168591145426d2d0ca8e6f51a75fa6a2-15.jpg?alt=media&token=a897b0a5-21d1-47e3-a780-ce139f94cb72'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U1L2%2F168591145426d2d0ca8e6f51a75fa6a2-16.jpg?alt=media&token=d9b8865f-3fe5-4447-bd8b-bb7543f17c9e'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U1L2%2F168591145426d2d0ca8e6f51a75fa6a2-17.jpg?alt=media&token=17239f9a-f3fd-47cc-91a7-f70feff03f69'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U1L2%2F168591145426d2d0ca8e6f51a75fa6a2-18.jpg?alt=media&token=b7777889-3831-471c-90ea-d1b90e4dcbf5'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U1L2%2F168591145426d2d0ca8e6f51a75fa6a2-19.jpg?alt=media&token=423e9ee0-e40a-4b03-9314-58d6924732bd'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U1L2%2F168591145426d2d0ca8e6f51a75fa6a2-20.jpg?alt=media&token=79c91ea6-6ec7-4b4d-9ed2-7b319b17461c'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U1L2%2F168591145426d2d0ca8e6f51a75fa6a2-21.jpg?alt=media&token=d4d3734d-54e8-40d2-9c50-0cf5046b5fbe'} alt="boohoo" className="img-responsive"/>
          </div>
         
        </Slider>
       
        </Grid.Column>
        <Grid.Column widescreen={1} largeScreen={1} computer={1}  tablet={1} mobile={1} > 
  
  </Grid.Column>

</Grid>
        
      //   </header>
      // </div>
      // </div>
    
    );
  }
}
