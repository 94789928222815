import React, { Component } from "react";
import {Button, Grid} from 'semantic-ui-react';
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import './B11U1L8.scss'

export default class B11U1L8 extends Component {

   
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1

      
    };
    return (
      
<Grid>
<Grid.Column widescreen={1} largeScreen={1} computer={1}  tablet={1} mobile={1} > 
  
  </Grid.Column>

  <Grid.Column widescreen={14} largeScreen={14} computer={14}  tablet={14} mobile={14} > 
     
        <h2><br></br>M2U5L1</h2>

        <Slider {...settings}>


          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U5L1%2Fd11b047e95487f7c8843b0dd9d1dbd1c-0.jpg?alt=media&token=8aa0693e-1075-4a68-b6ed-af23c3de5343'} alt="boohoo" className="img-responsive"/>
          <br/>
          </div>

          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U5L1%2Fd11b047e95487f7c8843b0dd9d1dbd1c-1.jpg?alt=media&token=529b5751-3d6d-4a08-a14f-2cd9aa2a27cd'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U5L1%2Fd11b047e95487f7c8843b0dd9d1dbd1c-2.jpg?alt=media&token=9872a3bf-adfd-433e-903b-455e8ac13fa8'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U5L1%2Fd11b047e95487f7c8843b0dd9d1dbd1c-3.jpg?alt=media&token=41d21da6-1e1a-48ef-baf8-d9cdbb1389bc'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U5L1%2Fd11b047e95487f7c8843b0dd9d1dbd1c-4.jpg?alt=media&token=7591790e-ee08-43c5-8f9c-e710b69a9aef'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U5L1%2Fd11b047e95487f7c8843b0dd9d1dbd1c-5.jpg?alt=media&token=c4b000af-9210-426b-b8b9-e0e3200afb4f'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U5L1%2Fd11b047e95487f7c8843b0dd9d1dbd1c-6.jpg?alt=media&token=3d250b5c-131b-454f-b0b7-d63d3aded0d0'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U5L1%2Fd11b047e95487f7c8843b0dd9d1dbd1c-7.jpg?alt=media&token=fd94574a-14cb-45c3-bd49-18d924fdfdde'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U5L1%2Fd11b047e95487f7c8843b0dd9d1dbd1c-8.jpg?alt=media&token=68c7ee3e-eb6f-4ae1-8c98-a86f7ac20005'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U5L1%2Fd11b047e95487f7c8843b0dd9d1dbd1c-9.jpg?alt=media&token=50e989ab-f445-4357-acab-1592f0b621be'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U5L1%2Fd11b047e95487f7c8843b0dd9d1dbd1c-10.jpg?alt=media&token=05fa3bed-e434-4e69-b2a8-e4a4d929477b'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U5L1%2Fd11b047e95487f7c8843b0dd9d1dbd1c-11.jpg?alt=media&token=b71d3a41-3c77-4029-bb65-fe6cba5c2fb6'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U5L1%2Fd11b047e95487f7c8843b0dd9d1dbd1c-12.jpg?alt=media&token=2356d1d8-831f-4faa-834f-2487c974949f'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U5L1%2Fd11b047e95487f7c8843b0dd9d1dbd1c-13.jpg?alt=media&token=ce7a018f-6966-426d-ad9a-06f27eeb8403'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U5L1%2Fd11b047e95487f7c8843b0dd9d1dbd1c-14.jpg?alt=media&token=6475c373-ba7d-4089-8399-d58d84964839'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U5L1%2Fd11b047e95487f7c8843b0dd9d1dbd1c-15.jpg?alt=media&token=30820b30-a843-4b9e-acbc-883d581c33f8'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U5L1%2Fd11b047e95487f7c8843b0dd9d1dbd1c-16.jpg?alt=media&token=0ceb8a86-aec5-4afd-bdf3-7be6919cbbcd'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U5L1%2Fd11b047e95487f7c8843b0dd9d1dbd1c-17.jpg?alt=media&token=1bda5666-d581-48ad-b3da-574161073bf0'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U5L1%2Fd11b047e95487f7c8843b0dd9d1dbd1c-18.jpg?alt=media&token=1216e6e8-9384-4f41-8d5c-b4236d4bea17'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U5L1%2Fd11b047e95487f7c8843b0dd9d1dbd1c-19.jpg?alt=media&token=0583bc5d-ba9b-4285-b56c-da4fc5c5299e'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U5L1%2Fd11b047e95487f7c8843b0dd9d1dbd1c-20.jpg?alt=media&token=f7606fce-3e8c-49db-9be3-f7089711aeae'} alt="boohoo" className="img-responsive"/>
          </div>
          
          
        </Slider>
        
             
</Grid.Column>
<Grid.Column widescreen={1} largeScreen={1} computer={1}  tablet={1} mobile={1} > 
  
  </Grid.Column>
    </Grid>

    );
  }
}
