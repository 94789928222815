import React, { Component } from "react";
import {Button, Grid} from 'semantic-ui-react';
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import './B11U1L4.scss'

export default class B11U1L4 extends Component {

   
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1

      
    };
    return (
      
<Grid>
<Grid.Column widescreen={1} largeScreen={1} computer={1}  tablet={1} mobile={1} > 
  
  </Grid.Column>

  <Grid.Column widescreen={14} largeScreen={14} computer={14}  tablet={14} mobile={14} > 
     
        <h2><br></br>M2U2L2</h2>

        <Slider {...settings}>


          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U2L2%2F46d15b9b12a5474beaf5e080f75f8097-0.jpg?alt=media&token=c872ea0f-258d-48d6-9874-2b44f8cc2366'} alt="boohoo" className="img-responsive"/>
          <br/>
          </div>

          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U2L2%2F46d15b9b12a5474beaf5e080f75f8097-1.jpg?alt=media&token=012fd943-b766-4b50-9199-c47144a9a8e7'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U2L2%2F46d15b9b12a5474beaf5e080f75f8097-2.jpg?alt=media&token=007f855f-80c1-4e7e-bc60-47213ec27766'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U2L2%2F46d15b9b12a5474beaf5e080f75f8097-3.jpg?alt=media&token=4cad606e-ed79-4a85-8c45-3604d382316c'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U2L2%2F46d15b9b12a5474beaf5e080f75f8097-4.jpg?alt=media&token=4b0add85-c5b9-4789-b07f-f67c5cfde2f1'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U2L2%2F46d15b9b12a5474beaf5e080f75f8097-5.jpg?alt=media&token=e2db75b2-addc-486e-92e7-068cb30a3645'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U2L2%2F46d15b9b12a5474beaf5e080f75f8097-6.jpg?alt=media&token=09826ec0-61b3-44e6-b8a1-55fb439c9cb0'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U2L2%2F46d15b9b12a5474beaf5e080f75f8097-7.jpg?alt=media&token=1b2c7f40-8d8e-4eef-ad26-aeeda93fb3c3'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U2L2%2F46d15b9b12a5474beaf5e080f75f8097-8.jpg?alt=media&token=07f9d6a8-9898-4c12-9918-247005567e5b'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U2L2%2F46d15b9b12a5474beaf5e080f75f8097-9.jpg?alt=media&token=f5dbdd5f-f7b9-4a27-a0e3-f80d8b85ef98'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U2L2%2F46d15b9b12a5474beaf5e080f75f8097-10.jpg?alt=media&token=0a7faaac-185d-4bfa-8039-98e11d62206b'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U2L2%2F46d15b9b12a5474beaf5e080f75f8097-11.jpg?alt=media&token=a3ee5bbd-6d5c-42e4-8d34-3c51f2488872'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U2L2%2F46d15b9b12a5474beaf5e080f75f8097-12.jpg?alt=media&token=f65daa18-ef65-4854-9e71-7f6fedd41f3a'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U2L2%2F46d15b9b12a5474beaf5e080f75f8097-13.jpg?alt=media&token=adc5dd90-ffb3-45ef-9817-72750ed07aaa'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U2L2%2F46d15b9b12a5474beaf5e080f75f8097-14.jpg?alt=media&token=6003418b-1eb2-4272-b297-1ccc5677fc27'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U2L2%2F46d15b9b12a5474beaf5e080f75f8097-15.jpg?alt=media&token=3305496a-f7d0-407f-869c-c28c74bd77ea'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U2L2%2F46d15b9b12a5474beaf5e080f75f8097-16.jpg?alt=media&token=3041345c-19ad-4087-94ba-a910dc42c8cf'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U2L2%2F46d15b9b12a5474beaf5e080f75f8097-17.jpg?alt=media&token=e74432dd-7b5a-488e-a29f-0230de6f509d'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U2L2%2F46d15b9b12a5474beaf5e080f75f8097-18.jpg?alt=media&token=49668760-5a58-471b-a6cf-c805a218fd82'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U2L2%2F46d15b9b12a5474beaf5e080f75f8097-19.jpg?alt=media&token=b61c0236-4093-461c-a46a-004bbd7db5ca'} alt="boohoo" className="img-responsive"/>
          </div>
          
        </Slider>
        
             
</Grid.Column>
<Grid.Column widescreen={1} largeScreen={1} computer={1}  tablet={1} mobile={1} > 
  
  </Grid.Column>
    </Grid>

    );
  }
}
