import React, { Component } from "react";
import {Button, Grid} from 'semantic-ui-react';
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import './B11U1L5.scss'

export default class B11U1L5 extends Component {

   
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1

      
    };
    return (
      
<Grid>
<Grid.Column widescreen={1} largeScreen={1} computer={1}  tablet={1} mobile={1} > 
  
  </Grid.Column>

  <Grid.Column widescreen={14} largeScreen={14} computer={14}  tablet={14} mobile={14} > 
     
        <h2><br></br>M2U3L1</h2>

        <Slider {...settings}>


          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U3L1%2F53016f745b6390b50a3a60174a1728f7-0.jpg?alt=media&token=c6c4b31b-c9e8-4f64-b10c-14fce110c343'} alt="boohoo" className="img-responsive"/>
          <br/>
          </div>

          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U3L1%2F53016f745b6390b50a3a60174a1728f7-1.jpg?alt=media&token=a8cabb4e-b441-4c00-92cd-efab6fb7a813'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U3L1%2F53016f745b6390b50a3a60174a1728f7-2.jpg?alt=media&token=be575aa6-5a72-4b12-8a46-879f3050dc2c'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U3L1%2F53016f745b6390b50a3a60174a1728f7-3.jpg?alt=media&token=d0f3ff7f-65d7-4326-aee3-85cb7822d964'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U3L1%2F53016f745b6390b50a3a60174a1728f7-4.jpg?alt=media&token=79aa6ec8-4718-4af9-94f0-501d6504e964'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U3L1%2F53016f745b6390b50a3a60174a1728f7-5.jpg?alt=media&token=8f2ebcab-dfd1-444d-a581-8da4fce6b3dd'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U3L1%2F53016f745b6390b50a3a60174a1728f7-6.jpg?alt=media&token=c1f9dc3c-d8b8-4c63-9baf-aafddfa48fcd'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U3L1%2F53016f745b6390b50a3a60174a1728f7-7.jpg?alt=media&token=d966c84a-15c1-48c0-988c-f97e6920b254'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U3L1%2F53016f745b6390b50a3a60174a1728f7-8.jpg?alt=media&token=26aa28cc-82f4-4f40-a4da-249fd7aa1e2a'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U3L1%2F53016f745b6390b50a3a60174a1728f7-9.jpg?alt=media&token=1e6a8103-4740-47a7-9994-5afdbf621b56'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U3L1%2F53016f745b6390b50a3a60174a1728f7-10.jpg?alt=media&token=2fda040c-2b4b-4b5a-bf9a-cd0927715e54'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U3L1%2F53016f745b6390b50a3a60174a1728f7-11.jpg?alt=media&token=0cf36561-71d9-4af2-9278-18ca218a3a65'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U3L1%2F53016f745b6390b50a3a60174a1728f7-12.jpg?alt=media&token=5cdfeb4b-93a2-49b7-8c0e-0c49d4e9bfe0'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U3L1%2F53016f745b6390b50a3a60174a1728f7-13.jpg?alt=media&token=fbe5f678-f494-456e-bf6d-dae49560d4d4'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U3L1%2F53016f745b6390b50a3a60174a1728f7-14.jpg?alt=media&token=c4453da2-ba9f-4912-96c2-c282224b923e'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U3L1%2F53016f745b6390b50a3a60174a1728f7-15.jpg?alt=media&token=b96b72ed-1cc2-4dd7-a43e-71e8c49843ff'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U3L1%2F53016f745b6390b50a3a60174a1728f7-16.jpg?alt=media&token=dca21c67-3b22-464b-8251-3b222bbce9a3'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U3L1%2F53016f745b6390b50a3a60174a1728f7-17.jpg?alt=media&token=91e2ac9b-5346-487d-826f-6b667e174248'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U3L1%2F53016f745b6390b50a3a60174a1728f7-18.jpg?alt=media&token=58e94355-6471-4e45-8791-93c190484d3a'} alt="boohoo" className="img-responsive"/>
          </div>
          <div>
          <img src={'https://firebasestorage.googleapis.com/v0/b/teacher-training-b84d4.appspot.com/o/Module%202%2FM2U3L1%2F53016f745b6390b50a3a60174a1728f7-19.jpg?alt=media&token=379b7b4f-7a5f-45b8-88f9-0d158be8bdb7'} alt="boohoo" className="img-responsive"/>
          </div>
          
          
        </Slider>
        
             
</Grid.Column>
<Grid.Column widescreen={1} largeScreen={1} computer={1}  tablet={1} mobile={1} > 
  
  </Grid.Column>
    </Grid>

    );
  }
}
